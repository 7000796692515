document.addEventListener("DOMContentLoaded", () => {
  // console.log("Loaded impressions");

  const impressions = []; // Array to store impressions
  const uniqueKeys = new Set(); // Set to track unique impressions
  let observer = null; // Declare observer variable

  // Initialize observer
  function initializeObserver() {
    // console.log("Initializing observer...");

    observer = new IntersectionObserver(
      (entries) => {
        // console.log("IntersectionObserver entries:", entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;

            const impressionData = {
              record_id: element.dataset.id,
              record_type: element.dataset.type,
              viewed: true,
              viewed_at: new Date().toISOString(),
              device_type: getDeviceType(),
              device_os: getDeviceOS(),
              device_screen_size: getDeviceScreenSize(),
              page_or_screen: window.location.pathname,
            };

            const impressionKey = `${impressionData.record_id}-${impressionData.record_type}-${window.location.pathname}`;

            if (!uniqueKeys.has(impressionKey)) {
              uniqueKeys.add(impressionKey);
              impressions.push(impressionData);
              // console.log("Added new impression:", impressionData);
            } else {
              // console.log("Duplicate impression skipped:", impressionData);
            }

            observer.unobserve(element); // Stop observing
          }
        });

        if (impressions.length > 0) {
          sendImpressionsToServer(impressions);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5, // Trigger when at least 50% is visible
      }
    );

    // Observe elements
    const elements = document.querySelectorAll(".track-impression");
    // console.log("Elements to observe:", elements);

    elements.forEach((el) => observer.observe(el));
  }

  // Reinitialize observer on dynamic page changes
  const mutationObserver = new MutationObserver(() => {
    // console.log("DOM updated, reinitializing observer");
    if (observer) observer.disconnect(); // Disconnect old observer
    initializeObserver(); // Reinitialize observer
  });

  // Observe changes in the body for new impressionable elements
  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Call the observer on page load
  initializeObserver();

  function sendImpressionsToServer(impressions) {
    // console.log("Posting impressions:", impressions);

    fetch("/api/services/v1/impressions/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ impressions }), // Send as array
    })
      .then((response) => {
        if (response.ok) {
          // console.log("Impressions sent successfully");
          impressions.length = 0; // Clear the array after successful send
        } else {
          // console.error("Failed to send impressions", response.statusText);
        }
      })
      .catch((err) => console.error("Failed to send impressions:", err));
  }

  function sendUserEngagement(element) {
    const engagementData = {
      record_id: element.dataset.id,
      record_type: element.dataset.type,
      viewed_at: new Date().toISOString(),
      device_type: getDeviceType(),
      device_os: getDeviceOS(),
      device_screen_size: getDeviceScreenSize(),
      page_or_screen: window.location.pathname,
      user_engagement: true, // Set engagement flag
    };

    fetch("/api/services/v1/impressions/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ impressions: [engagementData] }), // Send as single impression
    })
      .then((response) => {
        if (response.ok) {
          // console.log("User engagement sent successfully");
        } else {
          // console.error("Failed to send user engagement", response.statusText);
        }
      })
      .catch((err) => console.error("Failed to send user engagement:", err));
  }

  function getDeviceType() {
    if (window.innerWidth <= 768) return "mobile phones";
    if (window.innerWidth <= 1024) return "tablets";
    return "desktop";
  }

  function getDeviceOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("windows")) return "Windows";
    if (userAgent.includes("mac os")) return "macOS";
    if (userAgent.includes("android")) return "Android";
    if (userAgent.includes("iphone") || userAgent.includes("ipad")) return "iOS";
    return "unknown";
  }

  function getDeviceScreenSize() {
    return `${window.innerWidth}x${window.innerHeight}`;
  }
});
